import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  const personalDetails = {
    name: "Abdelkader Bouallaga",
    location: "Earth",
    tagline: "I'm a Devops Engineer.",
    email: "contact@babdelka.com",
    availability: "available",
    brand:
      "blablab bla",
  };

  return (
    <>
      <Header />
      <AnimatedRoutes personalDetails={personalDetails} />
    </>
  );
}

export default App;
