import aboutMeImg from "../images/aboutme.jpg";
import { motion } from "framer-motion";
import SocialIcons from "../components/SocialIcons";
import { useInView } from "react-intersection-observer";
import { useState, useEffect } from "react";
// import resume from "../pages/about/michael-yeates-resume.pdf";

const AboutMe = ({ name, email, location, availability, brand }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setDownloading(false);
  }, [downloading]);

  // const handleDownload = () => {
  //   setDownloading(true);
  //   const link = document.createElement("a");
  //   // link.href = resume;
  //   // link.download = "Michael-Yeates-Resume.pdf";
  //   // link.onload = () => {
  //   //   link.remove();
  //   //   setDownloading(false);
  //   // };
  //   document.body.appendChild(link);
  //   link.click();
  // };

  return (
    <div className="aboutContainer container">
      <div className="row">
        <motion.div
          className="personalImage col-12 col-lg-4"
          ref={ref}
          initial={{ x: "-10vw", opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: "-10vw", opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <img src={aboutMeImg} alt={name} />
        </motion.div>
        <motion.div
          className="personalInfo col-12 col-lg-8"
          ref={ref}
          initial={{ x: "10vw", opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: "10vw", opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <div className="contentContainer">
            <h5>I'm a DevOps professional with a passion for designing, deploying, and maintaining automated software delivery processes that improve efficiency and reduce errors. As a graduate from 1337 school, I've gained a solid foundation in programming and technical skills, which I apply to my work every day.
            </h5>
            <div className="contentDescription">
            <p>
My experience includes working closely with development teams, operations teams, and other stakeholders to ensure that software is delivered quickly, reliably, and securely. I'm dedicated to helping organizations achieve their goals by optimizing their software delivery processes, reducing downtime, and improving the overall quality of their applications.
            </p>
            <p>
On this website, you'll find information about my experience, skills, and projects, as well as my thoughts on the latest trends in DevOps. Feel free to reach out to me if you have any questions or if you're interested in working together on a project. Let's build something great!"
</p>
            </div>
            <div className="infoContainer">
              <div className="row">
                <div className="col-12 col-md-6 info">
                  <span>Name:</span>
                  <p>{name}</p>
                </div>
                <div className="col-12 col-md-6 info">
                  <span>Email:</span>
                  <p>
                    <a href={`mailto:${email}`}>{email}</a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 info">
                  <span>Location:</span>
                  <p>{location}</p>
                </div>
                <div className="col-12 col-md-6 info">
                  <span>Availability:</span>
                  <p>{availability}</p>
                </div>
              </div>
            </div>
            <div className="buttonContainer">
              {/* <button className="btn downloadCV" onClick={handleDownload} disabled={downloading}>
                {downloading ? "Downloading..." : "Download Resume"}
              </button>{" "} */}
              <SocialIcons />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutMe;
