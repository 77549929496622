// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* --- MOBILE STYLES --- */

.landing .name {
  font-size: 48px;
}
.landing .description {
  font-size: 26px;
}

.landingImage {
  right: 0px;
}

/* .landing .socialIcons {
  display: none;
} */

.landing .socialIcons {
  display: flex;
  position: absolute;
  left: 50px;
  bottom: 20px;
}

/* --- MEDIA QUERIES --- */

@media (min-width: 750px) {
  .landing .name {
    font-size: 70px;
  }

  .landing .description {
    font-size: 32px;
  }

  .landingImage {
    bottom: 0;
    right: 80px;
  }

  .landing .socialIcons {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/landing/landing.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;;GAEG;;AAEH;EACE,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,YAAY;AACd;;AAEA,0BAA0B;;AAE1B;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,SAAS;IACT,WAAW;EACb;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":["/* --- MOBILE STYLES --- */\n\n.landing .name {\n  font-size: 48px;\n}\n.landing .description {\n  font-size: 26px;\n}\n\n.landingImage {\n  right: 0px;\n}\n\n/* .landing .socialIcons {\n  display: none;\n} */\n\n.landing .socialIcons {\n  display: flex;\n  position: absolute;\n  left: 50px;\n  bottom: 20px;\n}\n\n/* --- MEDIA QUERIES --- */\n\n@media (min-width: 750px) {\n  .landing .name {\n    font-size: 70px;\n  }\n\n  .landing .description {\n    font-size: 32px;\n  }\n\n  .landingImage {\n    bottom: 0;\n    right: 80px;\n  }\n\n  .landing .socialIcons {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
